import { CommonState, API } from '@primeit/components-vue';
import moment from 'moment';
import { Config } from '@dav/security-component-spa';

const module = {
  actions: {
    FETCH_TO_METRICS_QUEUE: async () => {
      const config = await Config.getInstance();
      const companyId = config.get('dav-company-id');
      const apiName = 'RoomAPI';
      const path = '/sendtoaggregator';
      const user = CommonState.getters['credential/CURRENT_USER'];
      const entryTime = moment(sessionStorage.getItem('ENTRY_TIME'), moment.ISO_8601);
      const endTime = moment();
      const duration = moment.duration(endTime.diff(entryTime)).asMinutes();

      const myInit = {
        body: {
          companyId: companyId,
          eventType: 'PERSON_LOGIN',
          personId: user.username,
          value: duration,
        },
        headers: {
          'x-dav-company-id': companyId,
        },
        response: true,
      };

      if (user) {
        myInit.headers['x-api-key'] = user.signInUserSession.accessToken.jwtToken;
      }

      try {
        await API.post(apiName, path, myInit);
      } catch (err) {
        console.error('error', err);
      }

      sessionStorage.removeItem('ENTRY_TIME');
    },
  },
  namespaced: true,
  strict: true,
};


CommonState.registerModule('personLogin', module);
